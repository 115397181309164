<template>
  <div class="academic-years">
    <lenon-list-page
      title="Unmarked Attendances (Current Term)"
      :columns="columns"
      :rows="attendances"
      :table-loading="tableLoading"
      :show-create="false"
      :min-height="attendances.length===1?180:0"
      :show-details="true"
      :show-search="true"
      :show-profile-photo="true"
    >
      <!--      <template slot="table-header">-->
      <!--        <div-->
      <!--          class="text-danger pl-1 text-small"-->
      <!--          style="margin-bottom:5px; font-style: italic;"-->
      <!--        >  **Please note that Lenon will include holidays and mid terms, simply ignore them**-->
      <!--        </div>-->
      <!--      </template>-->
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -30px;"
        >
          <!--                    <lenon-select-->
          <!--                      v-model="academicYearId"-->
          <!--                      placeholder="Academic Year"-->
          <!--                      label-name="name"-->
          <!--                      value-name="id"-->
          <!--                      class="mr-1"-->
          <!--                      :options="academicYears"-->
          <!--                    />-->
          <!--          <lenon-select-->
          <!--            v-model="termId"-->
          <!--            placeholder="Select Term"-->
          <!--            label-name="name"-->
          <!--            value-name="id"-->
          <!--            class="mr-1"-->
          <!--            :options="filteredTerms"-->
          <!--          />-->
        </div>
      </template>
      <template #row-details="{row}">
        <b-card>
          <b-row
            class="mb-2"
          >
            <b-col
              md="12"
              class="mb-1"
            >
              <div style="font-size: 13px;text-decoration: underline">
                Attendance was not recorded on the following days:
              </div>
              <b-row class="px-1">
                <div
                  v-for="(date,index) in row.item.dates"
                  :key="index"
                  style="margin-bottom: 5px; margin-right: 3px;"
                >
                  <b-badge variant="light-danger">
                    {{ date }}
                  </b-badge>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
      <template #notify="{row}">
        <lenon-button
          icon-only
          variant="flat-primary"
          icon="MessageCircleIcon"
          tool-tip-text="Send a reminder to class teacher"
          @onClick="notifyTeacher(row.item)"
        />
        <!--            <b-badge v-if="!row.item.dates.length" variant="light-success">-->
        <!--                all marked <feather-icon icon="CheckIcon"/>-->
        <!--            </b-badge>-->
        <!--            <b-badge v-if="row.item.dates.length && (row.item.class_teacher==='No Class Teacher')" variant="light-danger">-->
        <!--               add class teacher first-->
        <!--            </b-badge>-->
      </template>
      <template #action="{row}">
        <lenon-button
          v-if="row.item.dates.length"
          icon-only
          variant="flat-danger"
          :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
          @onClick="row.toggleDetails"
        />
        <b-badge
          v-else
          variant="light-success"
        >
          all marked <feather-icon icon="CheckIcon" />
        </b-badge>
      </template>
    </lenon-list-page>
  </div>
</template>
<script>

import {
  BBadge, BCard, BCol, BRow,
} from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import confirm from '@/lenon/mixins/confirm'
import { FETCH_UNMARKED_ATTENDANCES_Q } from '@/graphql/queries'
import LenonButton from '@/lenon/components/LenonButton'

export default {
  name: 'Recordings',
  components: {
    LenonButton,
    LenonSelect,
    LenonListPage,
    BBadge,
    BCard,
    BCol,
    BRow,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      academicYearId: null,
      termId: null,
      tableLoading: false,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'class_teacher',
          label: 'Class Teacher Name',
        },
        {
          key: 'class_name',
          label: 'Class',
        },
        {
          key: 'total',
          label: 'Total Unmarked',
        },
        {
          key: 'action',
          label: 'View Days',
        },
        // {
        //   key: 'notify',
        //   label: 'Notify',
        // },
      ],
    }
  },
  computed: {
    yearTerm() {
      return (+this.academicYearId) + (+this.termId)
    },
    // totalUnmarked() {
    //   let total = 0
    //   this.attendances.forEach(trips => {
    //     total += trips.total
    //   })
    //   return total
    // },
    attendances() {
      return this.$store.getters['students/unmarkedAttendances']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.current)
    },
  },
  watch: {
    yearTerm(data) {
      this.fetchAttendances()
    },
  },
  mounted() {
    const setting = this.$store.getters['students/previousUnmarkedAttendanceSetting']
    this.academicYearId = setting.academic_year_id
    this.termId = setting.term_id
    this.fetchAttendances()
  },
  methods: {
    notifyTeacher(record) {
      // v-if="row.item.dates.length && row.item.class_teacher!=='No Class Teacher'"
    },
    // eslint-disable-next-line camelcase
    fetchAttendances() {
      // this.academicYearId = this.academicYear.id
      // if (!this.termId || !this.academicYearId) {
      //   this.$store.commit('students/setUnmarkedAttendances', [])
      //   return
      // }
      // current ay and term will be used
      const payload = {
        academic_year_id: 0,
        term_id: 0,
      }
      this.$store.commit('students/setPreviousUnmarkedAttendanceSettings', payload)
      this.tableLoading = true
      this.$apollo.query({
        query: FETCH_UNMARKED_ATTENDANCES_Q,
        variables: payload,
      })
        .then(res => {
          this.$store.commit('students/setUnmarkedAttendances', res.data.unmarkedAttendances)
          this.tableLoading = false
        })
        .catch(err => {
          this.showError('Failed to load unmarked attendances')
          this.tableLoading = false
        })
    },
  },
}
</script>
