var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"academic-years"},[_c('lenon-list-page',{attrs:{"title":"Unmarked Attendances (Current Term)","columns":_vm.columns,"rows":_vm.attendances,"table-loading":_vm.tableLoading,"show-create":false,"min-height":_vm.attendances.length===1?180:0,"show-details":true,"show-search":true,"show-profile-photo":true},scopedSlots:_vm._u([{key:"row-details",fn:function(ref){
var row = ref.row;
return [_c('b-card',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('div',{staticStyle:{"font-size":"13px","text-decoration":"underline"}},[_vm._v(" Attendance was not recorded on the following days: ")]),_c('b-row',{staticClass:"px-1"},_vm._l((row.item.dates),function(date,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px","margin-right":"3px"}},[_c('b-badge',{attrs:{"variant":"light-danger"}},[_vm._v(" "+_vm._s(date)+" ")])],1)}),0)],1)],1)],1)]}},{key:"index",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.index + 1)+" ")]}},{key:"notify",fn:function(ref){
var row = ref.row;
return [_c('lenon-button',{attrs:{"icon-only":"","variant":"flat-primary","icon":"MessageCircleIcon","tool-tip-text":"Send a reminder to class teacher"},on:{"onClick":function($event){return _vm.notifyTeacher(row.item)}}})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.item.dates.length)?_c('lenon-button',{attrs:{"icon-only":"","variant":"flat-danger","icon":row.detailsShowing?'EyeOffIcon':'EyeIcon'},on:{"onClick":row.toggleDetails}}):_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" all marked "),_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1)]}}])},[_c('template',{slot:"right-extra-header-actions"},[_c('div',{staticClass:"d-flex align-self-center align-items-start",staticStyle:{"margin-bottom":"-30px"}})])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }